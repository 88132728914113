///coin-holding
	.coin-holding{
		padding:15px 20px;
		align-items:center;
		border-radius:15px;
		position:relative;
		margin-right: 0;
		margin-left: 0;
		z-index:1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		.coin-font{
			font-size:28px;
			 @include custommq($max: 1600px) {
				font-size:16px;
			}
			@include respond ('tab-land'){
				font-size:16px;
			}
			@include respond ('phone'){
				font-size:20px;
			}
			
		}
		.coin-font-1{
			font-size:30px;
			font-weight: 600;
			 @include custommq($max: 1600px) {
				font-size:16px;
			}
			@include respond ('tab-land'){
				font-size:16px;
			}
		}
		 @include custommq($max: 1600px) {
			.coin-bx{
				svg{
					width:50px;
				}
			}	
		}
		@include respond ('phone'){
			border-radius: 6px;
			.coin-bx{
				width:100%;
				svg{
					width:50px;
				}
			}
			.coin-bx-one{
				svg{
					width:20px;
				}
			}
			p{
				font-size:11px;
			}
		}
	}
	
//gradients
.bg-gradient-1{
	background: -moz-linear-gradient(left,  rgba(98,126,234,0.12) 0%, rgba(98,126,234,0) 100%); 
	background: -webkit-linear-gradient(left,  rgba(98,126,234,0.12) 0%,rgba(98,126,234,0) 100%);
	background: linear-gradient(to right,  rgba(98,126,234,0.12) 0%,rgba(98,126,234,0) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f627eea', endColorstr='#00627eea',GradientType=1 ); 
}
.bg-gradient-2{
	background: -moz-linear-gradient(left,  rgba(52,93,157,0.12) 0%, rgba(52,93,157,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(52,93,157,0.12) 0%,rgba(52,93,157,0) 100%); 
	background: linear-gradient(to right,  rgba(52,93,157,0.12) 0%,rgba(52,93,157,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f345d9d', endColorstr='#00345d9d',GradientType=1 ); 
}	
.bg-gradient-3{
	background: -moz-linear-gradient(left,  rgba(247,147,26,0.12) 0%, rgba(247,147,26,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(247,147,26,0.12) 0%,rgba(247,147,26,0) 100%); 
	background: linear-gradient(to right,  rgba(247,147,26,0.12) 0%,rgba(247,147,26,0) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ff7931a', endColorstr='#00f7931a',GradientType=1 );
}
.bg-gradient-4{
	background: -moz-linear-gradient(left,  rgba(247,147,26,0.12) 0%, rgba(255,104,3,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(247,147,26,0.12) 0%,rgba(255,104,3,0) 100%); 
	background: linear-gradient(to right,  rgba(247,147,26,0.12) 0%,rgba(255,104,3,0) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ff7931a', endColorstr='#00ff6803',GradientType=1 );
}
.my-profile{
	position:relative;
	display:inline-block;
	img{
		height:195px;
		width:195px;
		@include respond ('tab-land'){
			height:100px;
			width:100px;
		}
	}
	a{
		position:absolute;
		height:52px;
		width:52px;
		text-align: center;
		line-height: 52px;
		border-radius: 52px;
		background:$white;
		color: var(--primary);
		font-size: 20px;
		bottom:0;
		right:0px;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
		@include respond ('tab-land'){
			height:35px;
			width:35px;
			line-height:35px;
			font-size:14px;
		}
	}
}
.portofolio-social{
	display:flex;
	justify-content:center;
	margin-top:30px;
	li{
		a{
			height:52px;
			width:52px;
			line-height:52px;
			font-size:24px;
			display:block;
			text-align:center;
			color:var(--primary);
			background:var(--rgba-primary-1);
			margin:0 8px;
			border-radius:52px;
			@include respond ('tab-land'){
				height:40px;
				width:40px;
				line-height:40px;
				font-size:20px;
			}
			
		}
	}
}	
.name-text{
	font-size:26px;
	font-weight:600;
}
