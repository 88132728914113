

//heart Blast Effect

.heart-blast {
    background-position: -1680px 0!important;
    transition: background 1s steps(28);
}

.heart {
    width   	: 60px;
    height  	: 60px;
    display 	: inline-block;
    background  : url('../images/like.png');
    cursor      : pointer;
    margin      : -25px -15px;
}

.weather-btn{
	display: flex;
    align-items: center;
	border:1px solid $border-color;
	border-radius:45px;
	margin-right:25px;
   
	span{
		padding: 11.5px 10px;
		align-items:center;
		border-bottom-left-radius:42px;
		border-top-left-radius:42px;
		padding-left: 13px;
		color:black;
		font-weight:600;
		background:$white;
		@include respond ('laptop'){
			padding:5.5px 8px;
		}
	}
}
.default-select{
	&.style-3{
		line-height:45px;
		border: 0;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius:42px;
		border-top-right-radius:42px;
		padding-left:0;
		padding-right:38px;
		font-weight:600;
		@include respond ('laptop'){
			line-height:30px;
		}
	}
}

.project-boxed {
    overflow: hidden;
}
	
.style-2{
	line-height:45px;
	border-radius:45px;
	color:black;
	font-weight:600;
	font-size:16px;
	border:0;
	padding-right: 35px;
	@include respond ('laptop'){
		line-height:31px;
	}
}
.invoice-num{
	font-size:30px;
	font-weight:600;
	@include respond ('phone-land'){
		font-size:24px;
	}
}
.invoice-num1{
	@include respond ('phone'){
		font-size:10px;
	}
}
.default-checkbox{
	.form-check-input{
		border-radius:30px;
	}
}
.border-hover{
	border-collapse:separate;
}
.card-bx{
	img{
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 0;
		border-radius: 1.25rem;
		object-fit: cover;
		@include respond ('phone'){
			border-radius: 6px;
		}
	}
	.card-info{
		position: relative;
		padding: 40px 30px;
		.num-text{
			font-size:28px;
		}
	}
}
.stacked{
	position: relative;
    z-index: 1;
}
.card-tabs.style-1{
	.nav-tabs{
		border:1px solid $border-color;
		border-radius:45px;
		@include respond ('phone'){
			width:max-content;
		}
		.nav-link{
			font-weight:500;
			padding: 10px 18px;
			border:0;
			border-bottom:0;
			border-radius:3rem;
			@include respond ('phone'){
				padding:8px 18px;
			}
			&.active,&:hover{
				background:var(--primary);
				color:$white;
			}
		}
	}
}

.tbl-orders{
	background: #EEEEEE;
    border-radius: 3rem;
	i{
		color:black;
	}
}
.quick-select{
	display:flex;
	align-items:center;
	border:1px solid $border-color;
	border-radius: 14px;
    padding: 0 13px;
	padding-right:0;
}
.form-wrapper{
	.form-group{
		margin-bottom:12px;
	}
	.input-group{
		.input-group-prepend{
			.input-group-text{
				background:#EEEEEE;
				border:0;
				font-size:16px;
				font-weight:500;
				width:210px;
				color:#342E59;
				height: 75px;
				border-radius:3rem;
				margin-right: -50px;
				z-index: 1;
				position: relative;
				@include respond ('phone'){
					width:115px;
				}
			}
		}
		.form-control{
			font-size:24px;
			height:75px;
			text-align:right;
			border-left:0;
			z-index:0;
			color: #342E59;
			border-top-right-radius: 3rem;
			border-bottom-right-radius: 3rem;
			&:focus{
				border-color:$border-color;
			}
		}
	}
	@include respond ('phone'){
		.input-group{
			.input-group-prepend{
				.input-group-text{
					font-size:14px;
					padding-left: 15px;
					
					@include respond ('phone'){
						font-size:12px;
						height:50px;
					}
				}
			}
			.form-control{
				font-size:16px;
				height:50px;
			}
		}
	}
}
.contacts-slider{
	img{
		height:106px;
		width:106px!important;
		@include respond ('phone'){
			height:85px;
			width:85px!important;
		}
		
	}
}
.card-slide{
	.card-bx{
		width:350px;
		@include respond ('phone'){
			width:auto;
		}
	}
}
.text-head{
	h2{
		font-size:34px;
		@include respond ('phone'){
			font-size:25px;
		}
	}
	@include respond ('phone'){
		margin-bottom:10px;
	}
}
.card-table{
	tbody{
		tr{
			td{
				@include respond ('phone'){
					padding:5px 10px;
				}
			}
		}
	}
}
.cardtbl-link{
	tbody{
		tr{
			td{
				a{
					border:2px solid;
					font-weight:500;
				}
			}
		}
	}
}
.order-tbl{
	thead{
		th{
			font-size:16px;
		}
	}
}
.view-link{
	a{
		font-size:16px;
		font-weight:500;
		color:#6418C3;
	}
}
.tbl-link{
	tr{
		td{
			&>a{
				font-weight:600;
			}
		}
	}
}
.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: -10px;
        z-index: 1;
        top: 0px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 130px;
        height: 95px;
        position: relative;
        border-radius: 30px;
        border: 3px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
			height: 100%;
            border-radius: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
			overflow: hidden;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover
			}
        }
    }
}